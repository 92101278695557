import { useNavigate, useRouteLoaderData } from '@remix-run/react'
import React from 'react'
import { Permission, Role, User } from '~common/generated/admin-graphql'
import { Error403 } from '~common/components/Error403'

export const hasPermissions = (
  requiredPermissions: Permission[] = [],
  user?: { roles: Role[] } | null,
  existingPermissions?: Permission[],
) => {
  if (!requiredPermissions.length) {
    return true
  }
  if (!user) {
    return false
  }
  if (requiredPermissions.length && !existingPermissions?.length) {
    return false
  }
  if (user && !requiredPermissions.length) {
    return true
  }
  for (let x = 0; x < requiredPermissions.length; x++) {
    if (existingPermissions?.includes(requiredPermissions[x])) {
      return true
    }
  }
  return false
}

const defaultChannelCreatePermissions = [
  Permission.CreateAdministrator,
  Permission.CreateChannel,
  Permission.CreateCountry,
  Permission.CreateFacet,
  Permission.CreateSeller,
  Permission.CreateSettings,
  Permission.CreateSystem,
  Permission.CreateTaxCategory,
  Permission.CreateTaxRate,
  Permission.CreateZone
]

export const usePermission = (
  requiredPermissions?: Permission[],
  customUser?: User | null,
) => {
  const { activeAdministrator, channel } = useRouteLoaderData<any>('root')
  const defaultUserPermission = React.useMemo(() => {
    const channelDefault = channel.code === '__default_channel__'
    return {
      user: customUser || activeAdministrator?.user,
      permissions: (customUser || activeAdministrator?.user)?.roles
        .reduce((acc: Permission[], val: Role) => {
          return Array.from(new Set<Permission>([...acc, ...val.permissions]))
        }, [])
        .filter(
          (p: Permission) =>
            !channelDefault ||
            (!p.toString().startsWith('Create') &&
              !defaultChannelCreatePermissions.includes(p)),
        ),
    }
  }, [customUser, activeAdministrator])
  const { user, permissions } = defaultUserPermission as unknown as {
    user: { roles: Role[] } | null
    permissions: Permission[] | undefined
  }

  const hasPermission = React.useMemo(() => {
    return hasPermissions(requiredPermissions, user, permissions)
  }, [user, permissions, requiredPermissions])

  return hasPermission
}

/**
 *
 * @param redirectToLogin Force a login if a required permission is missing or the user is not logged in.
 * Otherwise return either an error component or null
 * @param displayError Displays an error if true and nulls the children (not rendering the content) if false. Defaults to false
 * @param errorComponent component to show in case of an authorization failure. Has a default
 * @param permissions Array of Permission objects that the user should have at least one of. If omitted, will just check for logged in user
 * @returns
 */
export const Auth: React.FC<
  React.PropsWithChildren<{
    redirectToLogin?: boolean
    displayError?: boolean
    errorComponent?: React.ReactNode
    permissions?: Permission[]
    // In some scenario's such as context wrapping layout, we have to pass the user manually
    customUser?: User
  }>
> = ({
  redirectToLogin = false,
  displayError,
  errorComponent,
  permissions: requiredPermissions = [],
  customUser = null,
  children,
}) => {
  const hasPermission = usePermission(requiredPermissions, customUser)
  const navigate = useNavigate()
  if (hasPermission) {
    return children
  }

  if (redirectToLogin) {
    navigate('/sign-in')
  }

  if (!displayError && !errorComponent) {
    return null
  }

  if (errorComponent) {
    return errorComponent
  }

  return <Error403 />
}
