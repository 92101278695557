import React from 'react'

export const Error403 = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="text-center">
        <p className="text-lg">403</p>
        <h3 className="mt-2 text-lg font-semibold text-gray-900 dark:text-slate-400">You don't have permission for this</h3>
        <p className="mt-1 text-sm text-gray-500">
          Try logging in with a different user
        </p>
        {/* <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New Project
          </button>
        </div> */}
      </div>
    </div>
  )
}
